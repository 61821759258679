import React, { useEffect } from "react";
import { clarity } from "react-microsoft-clarity";

const LayoutWrapper = ({ children }) => {
  useEffect(() => {
    clarity.init("l9dmea36bz");
  }, []);

  return <>{children}</>;
};

export default LayoutWrapper;
