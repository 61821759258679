let initialState = {
  basket: {},
};

export function basketReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_BASKET": {
      return Object.assign({}, state, {
        basket: action.basket,
      });
    }
    default:
      return state;
  }
}
