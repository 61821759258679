let initialState = {
  playing: false,
  singer: {},
  song: {},
  playlist: [],
  index: 0,
};

export function playerReducer(state = initialState, action) {
  switch (action.type) {
    case "PLAY_MUSIC": {
      return Object.assign({}, state, {
        playing: true,
        song: action.song,
        singer: action.singer,
      });
    }
    case "PAUSE_MUSIC": {
      return Object.assign({}, state, { playing: false });
    }
    case "RESUME_MUSIC": {
      return Object.assign({}, state, { playing: true });
    }
    case "STOP_MUSIC": {
      return Object.assign({}, state, {
        playing: false,
        song: {},
        singer: {},
      });
    }
    case "CREATE_PLAYLIST": {
      return Object.assign({}, state, { playlist: action.playlist });
    }
    case "SET_INDEX": {
      return Object.assign({}, state, { index: action.index });
    }
    default:
      return state;
  }
}
