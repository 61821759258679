import React, { useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import ChatXl from "./ChatXl";
import ChatXs from "./ChatXs";
import ChatSm from "./ChatSm";

const Chat = (props) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      {/* <MediaQuery minWidth={320} maxWidth={480}>
        <ChatXs show={show} {...props} />
      </MediaQuery>
      <MediaQuery minWidth={481} maxWidth={768}>
        <ChatSm show={show} {...props} />
      </MediaQuery>
      <MediaQuery minWidth={769}>
        <ChatXl show={show} {...props} />
      </MediaQuery> */}
    </React.Fragment>
  );
};

export default Chat;
