import { combineReducers } from "redux";
import { playerReducer } from "./player";
import { authReducer } from "./auth";
import { basketReducer } from "./basket";
import { contentReducer } from "./content";
import { notificationReducer } from "./notification";

export default combineReducers({
  playerReducer,
  authReducer,
  basketReducer,
  contentReducer,
  notificationReducer,
});
