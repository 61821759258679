let initialState = {
  list: [],
};

export function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case "PUSH_NOTIFITCATION": {
      let temp = [...state.list];
      temp.push({
        status: action.status,
        message: action.message,
      });
      return Object.assign({}, state, { list: temp });
    }
    case "POP_NOTIFICATION": {
      let temp = [...state.list];
      temp.splice(action.index, 1);
      return Object.assign({}, state, { list: temp });
    }
    default:
      return state;
  }
}
