import React from "react";
import Fade from "react-reveal/Fade";
import "./styles/ChatXl.scss";

const ChatXl = (props) => {
  return (
    <div className="chat-outer-container-xl">
      <Fade duration={500} left={true} when={props.show}>
        <div className="chat-inner-container-xl">
          <img
            alt="chat"
            className="chat-xl"
            src={require("./assets/chat.svg").default}
            onClick={() => {
              if (window.AndroidWebView) {
                window.AndroidWebView.onMessage(
                  JSON.stringify({
                    type: "ACTION_OPEN_URL",
                    url: "https://api.whatsapp.com/send?phone=+989903307925",
                  })
                );
              } else {
                window.open(
                  "https://api.whatsapp.com/send?phone=+989903307925"
                );
              }
            }}
          />
        </div>
      </Fade>
    </div>
  );
};

export default ChatXl;
