import persianJs from "persianjs";

export const ssoUrl = "https://accounts.kplus.holdings/?srv=itunes";

export const baseURL = "https://api.itunes.ir/";

export function pn(input) {
  if (input === 0 || input === "") return persianJs("0");
  else return persianJs(input);
}

export function extractPath(url) {
  let match = url.match(/^https?:\/\/[^\/]+(\/.*)$/);
  return match ? match[1]?.slice(1) : null;
}