import axios from "axios";
import { baseURL } from "../../../Global";

export function checkToken(token, callback) {
  axios
    .post(`${baseURL}v1/usr/sso/`, {
      token: token,
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}
