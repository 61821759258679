/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { baseURL } from "../../Global";
import { checkToken } from "./network/sso";

const SSO = (props) => {
  const history = useHistory();
  const [urlParams] = useState(new URLSearchParams(window.location.search));
  const redirect_to_home = () => {
    window.location = "https://itunes.ir/";
    // window.location = 'http://localhost:3000/'
  };

  useEffect(() => {
    if (urlParams.get("token") && urlParams.get("token") !== "") {
      checkToken(urlParams.get("token"), (response) => {
        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          if (
            urlParams.get("redirect_url") &&
            urlParams.get("redirect_url") !== ""
          ) {
            window.location = urlParams.get("redirect_url");
          } else {
            redirect_to_home();
          }
        } else {
          redirect_to_home();
        }
      });
    } else {
      redirect_to_home();
    }
  }, []);

  return <></>;
};

export default SSO;
