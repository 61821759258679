let initialState = {
  user: {},
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_USER": {
      return Object.assign({}, state, {
        user: action.user,
      });
    }
    default:
      return state;
  }
}
