/*eslint react-hooks/exhaustive-deps:off */
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import { store } from "./store";
import localforage from "localforage";
import "./font.scss";
import "./styles.scss";
import "./preprocessing/normalize.scss";
import Chat from "./components/Chat";
import Test from "./components/Test";
import SSO from "./components/SSO";
import LayoutWrapper from "./components/layout/LayoutWrapper";
// import WebVitals from "./web-vitals";

const WalletPageCharkhoneh = lazy(() => import("./components/WalletPage/charkhoneh"));
const WalletPageWeb = lazy(() => import("./components/WalletPage/web"));
const WalletPageBazaar = lazy(() => import("./components/WalletPage/bazaar"));
const WalletPageGplay = lazy(() => import("./components/WalletPage/gplay"));
const WalletPageMyket = lazy(() => import("./components/WalletPage/myket"));
const Notification = lazy(() => import("./components/Notification"));
const DownloadPage = lazy(() => import("./components/DownloadPage"));
const Analytics = lazy(() => import("./components/Analytics"));
const DownloadBanner = lazy(() => import("./components/DownloadBanner"));
const Navbar = lazy(() => import("./components/Navbar"));
const Player = lazy(() => import("./components/Player"));
const Header = lazy(() => import("./components/Header"));
const Login = lazy(() => import("./components/Login"));
const NotFound = lazy(() => import("./components/NotFound"));
const AlbumPublisherList = lazy(() => import("./components/AlbumPublisherList"));
const AlbumPublisher = lazy(() => import("./components/AlbumPublisher/"));
const TestTrack = lazy(() => import("./components/TestTrack/"));
const Contact = lazy(() => import("./components/Contact"));
const NewsList = lazy(() => import("./components/NewsList"));
const NewsPage = lazy(() => import("./components/NewsPage"));
const ListPage = lazy(() => import("./components/ListPage"));
const Payment = lazy(() => import("./components/Payment"));
const Profile = lazy(() => import("./components/Profile"));
const Category = lazy(() => import("./components/Category"));
const CategoryItem = lazy(() => import("./components/CategoryItem"));
const CategoryItemType = lazy(() => import("./components/CategoryItemType"));
const PurchaseList = lazy(() => import("./components/PurchaseList"));
const AlbumSale = lazy(() => import("./components/AlbumSale"));
const VideoPage = lazy(() => import("./components/VideoPage"));
const VideoList = lazy(() => import("./components/VideoList"));
const MusicPage = lazy(() => import("./components/MusicPage"));
const TrackPage = lazy(() => import("./components/TrackPage"));
const Referral = lazy(() => import("./components/Referral"));
const SingerPage = lazy(() => import("./components/SingerPage"));
const ConcertPage = lazy(() => import("./components/ConcertPage"));
const ResultPage = lazy(() => import("./components/ResultPage"));
const ClubPage = lazy(() => import("./components/ClubPage"));
const AlbumCategory = lazy(() => import("./components/AlbumCategory"));
const RingtonePage = lazy(() => import("./components/RingtonePage"));
const MoodPage = lazy(() => import("./components/MoodPage"));
const FeedPage = lazy(() => import("./components/FeedPage"));
const StoryList = lazy(() => import("./components/StoryList"));
const StoryPage = lazy(() => import("./components/StoryPage"));
const CreditPage = lazy(() => import("./components/CreditPage"));
const AlbumList = lazy(() => import("./components/AlbumList"));
const PresaleList = lazy(() => import("./components/PresaleList"));
const About = lazy(() => import("./components/About"));
const Terms = lazy(() => import("./components/Terms"));
const FaqPage = lazy(() => import("./components/FaqPage"));
const Results = lazy(() => import("./components/Results"));
const PlaylistList = lazy(() => import("./components/PlaylistList"));
const PlaylistPage = lazy(() => import("./components/PlaylistPage"));
const BasketPage = lazy(() => import("./components/BasketPage"));

ReactGA.initialize("UA-222368081-4");
localforage.setDriver(localforage.INDEXEDDB);

class App extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    if (!localStorage.getItem("last-visited")) {
      localStorage.setItem("last-visited", JSON.stringify({ list: [] }));
    }
  }

  renderAlbumPublisherListPage() {
    return (
      <Suspense fallback={<div></div>}>
        <AlbumPublisherList {...this.props} />
      </Suspense>
    );
  }

  renderAlbumPublisherPage() {
    return (
      <Suspense fallback={<div></div>}>
        <AlbumPublisher {...this.props} />
      </Suspense>
    );
  }

  renderTestTrackPage() {
    return (
      <Suspense fallback={<div></div>}>
        <TestTrack {...this.props} />
      </Suspense>
    );
  }

  renderResultsPage() {
    return (
      <Suspense fallback={<div></div>}>
        <ResultPage {...this.props} />
      </Suspense>
    );
  }

  renderResults() {
    return (
      <Suspense fallback={<div></div>}>
        <Results {...this.props} />
      </Suspense>
    );
  }

  renderMusicPage() {
    return (
      <Suspense fallback={<div></div>}>
        <MusicPage {...this.props} />
      </Suspense>
    );
  }

  renderListPage() {
    return (
      <Suspense fallback={<div></div>}>
        <ListPage {...this.props} />
      </Suspense>
    );
  }

  renderNewsListPage() {
    return (
      <Suspense fallback={<div></div>}>
        <NewsList {...this.props} />
      </Suspense>
    );
  }

  renderNewsPage() {
    return (
      <Suspense fallback={<div></div>}>
        <NewsPage {...this.props} />
      </Suspense>
    );
  }

  renderAlbumSale() {
    return (
      <Suspense fallback={<div></div>}>
        <AlbumSale {...this.props} />
      </Suspense>
    );
  }

  renderMoodPage() {
    return (
      <Suspense fallback={<div></div>}>
        <MoodPage {...this.props} />
      </Suspense>
    );
  }

  renderSingerPage() {
    return (
      <Suspense fallback={<div></div>}>
        <SingerPage {...this.props} />
      </Suspense>
    );
  }

  renderVideoPage() {
    return (
      <Suspense fallback={<div></div>}>
        <VideoPage {...this.props} />
      </Suspense>
    );
  }

  renderTermsPage() {
    return (
      <Suspense fallback={<div></div>}>
        <Terms {...this.props} />
      </Suspense>
    );
  }

  renderFaqPage() {
    return (
      <Suspense fallback={<div></div>}>
        <FaqPage {...this.props} />
      </Suspense>
    );
  }

  renderAboutPage() {
    return (
      <Suspense fallback={<div></div>}>
        <About {...this.props} />
      </Suspense>
    );
  }

  renderPaymentPage() {
    return (
      <Suspense fallback={<div></div>}>
        <Payment {...this.props} />
      </Suspense>
    );
  }

  renderProfilePage() {
    return (
      <Suspense fallback={<div></div>}>
        <Profile {...this.props} />
      </Suspense>
    );
  }

  renderContactPage() {
    return (
      <Suspense fallback={<div></div>}>
        <Contact {...this.props} />
      </Suspense>
    );
  }

  renderTrackPage() {
    return (
      <Suspense fallback={<div></div>}>
        <TrackPage {...this.props} />
      </Suspense>
    );
  }

  renderRingtonePage() {
    return (
      <Suspense fallback={<div></div>}>
        <RingtonePage {...this.props} />
      </Suspense>
    );
  }

  renderAlbumCategoryPage() {
    return (
      <Suspense fallback={<div></div>}>
        <AlbumCategory {...this.props} />
      </Suspense>
    );
  }

  renderCategoryPage() {
    return (
      <Suspense fallback={<div></div>}>
        <Category {...this.props} />
      </Suspense>
    );
  }

  renderCategoryItemPage() {
    return (
      <Suspense fallback={<div></div>}>
        <CategoryItem {...this.props} />
      </Suspense>
    );
  }

  renderCategoryItemTypePage() {
    return (
      <Suspense fallback={<div></div>}>
        <CategoryItemType {...this.props} />
      </Suspense>
    );
  }

  renderVideoListPage() {
    return (
      <Suspense fallback={<div></div>}>
        <VideoList {...this.props} />
      </Suspense>
    );
  }

  renderPurchaseListPage() {
    return (
      <Suspense fallback={<div></div>}>
        <PurchaseList {...this.props} />
      </Suspense>
    );
  }

  renderReferralPage() {
    return (
      <Suspense fallback={<div></div>}>
        <Referral {...this.props} />
      </Suspense>
    );
  }

  renderClubPage() {
    return (
      <Suspense fallback={<div></div>}>
        <ClubPage {...this.props} />
      </Suspense>
    );
  }

  renderCreditPage() {
    return (
      <Suspense fallback={<div></div>}>
        <CreditPage {...this.props} />
      </Suspense>
    );
  }

  renderAlbumListPage() {
    return (
      <Suspense fallback={<div></div>}>
        <AlbumList {...this.props} />
      </Suspense>
    );
  }

  renderPresaleListPage() {
    return (
      <Suspense fallback={<div></div>}>
        <PresaleList {...this.props} />
      </Suspense>
    );
  }

  renderPlaylistPage() {
    return (
      <Suspense fallback={<div></div>}>
        <PlaylistPage {...this.props} />
      </Suspense>
    );
  }

  renderPlaylistList() {
    return (
      <Suspense fallback={<div></div>}>
        <PlaylistList {...this.props} />
      </Suspense>
    );
  }

  renderStoryList() {
    return (
      <Suspense fallback={<div></div>}>
        <StoryList {...this.props} />
      </Suspense>
    );
  }

  renderStoryPage() {
    return (
      <Suspense fallback={<div></div>}>
        <StoryPage {...this.props} />
      </Suspense>
    );
  }

  renderBasketPage() {
    return (
      <Suspense fallback={<div></div>}>
        <BasketPage {...this.props} />
      </Suspense>
    );
  }

  renderConcertPage() {
    return (
      <Suspense fallback={<div></div>}>
        <ConcertPage {...this.props} />
      </Suspense>
    );
  }

  renderFeedPage() {
    return (
      <Suspense fallback={<div></div>}>
        <FeedPage {...this.props} />
      </Suspense>
    );
  }

  renderDownloadAppPage() {
    return (
      <Suspense fallback={<div></div>}>
        <DownloadPage {...this.props} />
      </Suspense>
    );
  }

  renderAnalytics() {
    return (
      <Suspense fallback={<div></div>}>
        <Analytics {...this.props} />
      </Suspense>
    );
  }

  renderNotification() {
    return (
      <Suspense fallback={<div></div>}>
        <Notification {...this.props} />
      </Suspense>
    );
  }

  renderTest() {
    return <Test {...this.props} />;
  }

  renderSSO() {
    return <SSO {...this.props} />;
  }

  renderDownloadBanner() {
    return (
      <Suspense fallback={<div></div>}>
        <DownloadBanner {...this.props} />
      </Suspense>
    );
  }

  renderPlayer() {
    return (
      <Suspense fallback={<div></div>}>
        <Player {...this.props} />
      </Suspense>
    );
  }

  renderLogin() {
    return (
      <Suspense fallback={<div></div>}>
        <Login {...this.props} />
      </Suspense>
    );
  }

  renderNavbar() {
    return (
      <Suspense fallback={<div></div>}>
        <Navbar {...this.props} />
      </Suspense>
    );
  }

  renderHeader() {
    return (
      <Suspense fallback={<div></div>}>
        <Header {...this.props} />
      </Suspense>
    );
  }

  renderWalletPage() {
    if (localStorage.getItem("platform") === "bazaar") {
      return (
        <Suspense fallback={<div></div>}>
          <WalletPageBazaar {...this.props} />
        </Suspense>
      );
    }
    if (localStorage.getItem("platform") === "web") {
      return (
        <Suspense fallback={<div></div>}>
          <WalletPageWeb {...this.props} />
        </Suspense>
      );
    }
    if (localStorage.getItem("platform") === "gplay") {
      return (
        <Suspense fallback={<div></div>}>
          <WalletPageGplay {...this.props} />
        </Suspense>
      );
    }
    if (localStorage.getItem("platform") === "myket") {
      return (
        <Suspense fallback={<div></div>}>
          <WalletPageMyket {...this.props} />
        </Suspense>
      );
    }
    if (localStorage.getItem("platform") === "charkhoneh") {
      return (
        <Suspense fallback={<div></div>}>
          <WalletPageCharkhoneh {...this.props} />
        </Suspense>
      );
    }
  }

  render404Page() {
    return (
      <Suspense fallback={<div></div>}>
        <NotFound {...this.props} />
      </Suspense>
    );
  }

  // renderWebVitals() {
  //   return (
  //     <WebVitals {...this.props} />
  //   );
  // }

  renderChat() {
    return <Chat {...this.props} />;
  }

  render() {
    return (
      <Provider store={store}>
        <LayoutWrapper>
          <Router>
            {/* <Route path="/" render={() => this.renderWebVitals()} /> */}
            <Route path="/" render={() => this.renderPlayer()} />
            <Route path="/" render={() => this.renderHeader()} />
            <Route path="/" render={() => this.renderNavbar()} />
            <Route path="/" render={() => this.renderDownloadBanner()} />
            <Route path="/" render={() => this.renderAnalytics()} />
            <Route path="/" render={() => this.renderNotification()} />
            <Route path="/" render={() => this.renderChat()} />
            <Switch>
              <Route path="/sso/" render={() => this.renderSSO()} />
              <Route path="/feature/test/" render={() => this.renderTest()} />
              <Route
                exact={true}
                path="/:type/feed/:id/:title/"
                render={() => this.renderFeedPage()}
              />
              <Route
                exact={true}
                path="/:type/wallet/charge/"
                render={() => this.renderWalletPage()}
              />
              <Route
                exact={true}
                path="/results/"
                render={() => this.renderResultsPage()}
              />
              <Route
                exact={true}
                path="/results/:category/"
                render={() => this.renderResults()}
              />
              <Route
                exact={true}
                path="/news/اخبار-موسیقی/"
                render={() => this.renderNewsListPage()}
              />
              <Route
                exact={true}
                path="/news/:slug/"
                render={() => this.renderNewsPage()}
              />
              <Route
                exact={true}
                path="/album/:id/:name/"
                render={() => this.renderAlbumSale()}
              />
              <Route
                exact={true}
                path="/album/:slug/"
                render={() => this.renderAlbumSale()}
              />
              <Route
                exact={true}
                path="/singers/خواننده-ها/"
                render={() => this.renderListPage()}
              />
              <Route
                path="/albums/آلبوم-ها/"
                exact={true}
                render={() => this.renderAlbumListPage()}
              />
              <Route
                exact={true}
                path="/singer/:id/:title/"
                render={() => this.renderSingerPage()}
              />
              <Route
                exact={true}
                path="/singer/:slug/"
                render={() => this.renderSingerPage()}
              />

              <Route
                exact={true}
                path="/video/"
                render={() => this.renderVideoListPage()}
              />
              <Route
                exact={true}
                path="/video/:id/:title/"
                render={() => this.renderVideoPage()}
              />
              <Route
                exact={true}
                path="/video/:slug/"
                render={() => this.renderVideoPage()}
              />
              <Route
                exact={true}
                path="/stories/استوری-ها/"
                render={() => this.renderStoryList()}
              />
              <Route
                exact={true}
                path="/story/:id/:title/"
                render={() => this.renderStoryPage()}
              />
              <Route exact={true} path="/login/" render={() => this.renderLogin()} />
              <Route
                exact={true}
                path="/music/profile/:tab/"
                render={() => this.renderProfilePage()}
              />
              <Route
                exact={true}
                path="/payment/"
                render={() => this.renderPaymentPage()}
              />
              <Route
                exact={true}
                path="/bazaar/"
                render={() => {
                  localStorage.setItem("platform", "bazaar");
                  return this.renderMusicPage();
                }}
              />
              <Route
                exact={true}
                path="/gplay/"
                render={() => {
                  localStorage.setItem("platform", "gplay");
                  return this.renderMusicPage();
                }}
              />
              <Route
                exact={true}
                path="/"
                render={() => {
                  localStorage.setItem("platform", "web");
                  return this.renderMusicPage();
                }}
              />
              <Route
                exact={true}
                path="/web/"
                render={() => {
                  localStorage.setItem("platform", "web");
                  return this.renderMusicPage();
                }}
              />
              <Route
                exact={true}
                path="/myket/"
                render={() => {
                  localStorage.setItem("platform", "myket");
                  return this.renderMusicPage();
                }}
              />
              <Route
                exact={true}
                path="/charkhoneh/"
                render={() => {
                  localStorage.setItem("platform", "charkhoneh");
                  return this.renderMusicPage();
                }}
              />
              <Route
                exact={true}
                path="/moods/مود-ها/"
                render={() => this.renderListPage()}
              />
              <Route
                exact={true}
                path="/mood/:id/:title/"
                render={() => this.renderMoodPage()}
              />
              <Route
                exact={true}
                path="/ringbacktones/آهنگ-های-پیشواز/"
                render={() => this.renderListPage()}
              />
              <Route
                exact={true}
                path="/ringtone/:id/:title/"
                render={() => this.renderRingtonePage()}
              />
              <Route
                exact={true}
                path="/category/"
                render={() => this.renderCategoryPage()}
              />
              <Route
                exact={true}
                path="/category/:id/:title/"
                render={() => this.renderCategoryItemPage()}
              />
              <Route
                exact={true}
                path="/category/:id/:title/:category/"
                render={() => this.renderCategoryItemTypePage()}
              />
              <Route
                exact={true}
                path="/albums/category/:title/"
                render={() => this.renderAlbumCategoryPage()}
              />
              <Route
                path="/concert/:id/:title/"
                render={() => this.renderConcertPage()}
              />
              <Route path="/track/:id/:title/" render={() => this.renderTrackPage()} />
              <Route path="/track/:slug/" render={() => this.renderTrackPage()} />
              <Route
                path="/:type/tos/قوانین-و-مقررات/"
                render={() => this.renderTermsPage()}
              />
              <Route
                path="/:type/faq/سوالات-متداول/"
                render={() => this.renderFaqPage()}
              />
              <Route
                path="/:type/about-us/درباره-ما/"
                render={() => this.renderAboutPage()}
              />
              <Route
                path="/:type/contact-us/تماس-با-ما/"
                render={() => this.renderContactPage()}
              />
              <Route
                path="/:type/referral/معرفی-به-دوستان/"
                render={() => this.renderReferralPage()}
              />
              <Route
                path="/:type/purchased-list/سفارش-های-من/"
                render={() => this.renderPurchaseListPage()}
              />
              <Route path="/club/باشگاه-رنگارنگ/" render={() => this.renderClubPage()} />
              <Route path="/:type/wallet/info/" render={() => this.renderCreditPage()} />
              <Route
                path="/:type/download/app/"
                render={() => this.renderDownloadAppPage()}
              />
              <Route
                path="/presale/پیش-فروش-آلبوم/"
                render={() => this.renderPresaleListPage()}
              />
              <Route
                path="/user/playlists/"
                exact={true}
                render={() => this.renderPlaylistList()}
              />
              <Route
                path="/user/playlist/:id/"
                exact={true}
                render={() => this.renderPlaylistPage()}
              />
              <Route
                path="/:type/user/basket/"
                exact={true}
                render={() => this.renderBasketPage()}
              />
              <Route
                path="/music/publisher/"
                exact={true}
                render={() => this.renderAlbumPublisherListPage()}
              />
              <Route
                path="/music/publisher/:id/:title/"
                exact={true}
                render={() => this.renderAlbumPublisherPage()}
              />
              <Route
                path="/music/publisher/:slug/"
                exact={true}
                render={() => this.renderAlbumPublisherPage()}
              />
              <Route
                path="/tunes.apk"
                onEnter={() => {
                  window.location.reload();
                }}
              />
              <Route
                path="/chaargoosh.apk"
                onEnter={() => {
                  window.location.reload();
                }}
              />
              <Route
                path="/test-track"
                exact={true}
                render={() => this.renderTestTrackPage()}
              />
              <Route path="*" render={() => this.render404Page()} />
            </Switch>
          </Router>
        </LayoutWrapper>
      </Provider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    registrations.forEach(function (worker) {
      worker.unregister().catch((error) => {
        console.log(error);
      });
    });
  });
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js", {
        scope: ".",
      })
      .then(() => {
        console.log("service worker registered");
      })
      .catch((error) => {
        console.log("error");
      });
  });
}
