/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import MediaQuery from "react-responsive";
import { Helmet } from "react-helmet";
import TestXl from "./TestXl";
import TestXs from "./TestXs";
import TestSm from "./TestSm";

const Test = (props) => {
  useEffect(() => {
    document.body.style.backgroundColor = "#FFFFFF";
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    //   window.plyr = new Plyr("#audio-player", {
    //     autoplay: false,
    //   });
    //   window.plyr.source = {
    //     type: "audio",
    //     sources: [
    //       {
    //         type: "audio/mp3",
    //         title: "test",
    //         src: "https://dls.music-fa.com/tagdl/NEW2022/Farhad%20Forootani%20-%20Man%20Kiam%20(128).mp3",
    //       },
    //     ],
    //   };
    // }, 1000);
  }, []);

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" />
        {/*<link rel="stylesheet" href="https://cdn.plyr.io/3.6.8/plyr.css" />*/}
      </Helmet>
      {/*<audio crossOrigin="" id="audio-player"></audio>*/}
      <MediaQuery minWidth={320} maxWidth={480}>
        <TestXs {...props} />
      </MediaQuery>
      <MediaQuery minWidth={481} maxWidth={768}>
        <TestSm {...props} />
      </MediaQuery>
      <MediaQuery minWidth={769}>
        <TestXl {...props} />
      </MediaQuery>
    </div>
  );
};

export default Test;
