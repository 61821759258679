let initialState = {
  main_page: {},
  book_page: {},
  music_page: {},
};

export function contentReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_MAIN_PAGE": {
      let temp = { ...state };
      temp.main_page = action.value;
      return Object.assign({}, state, temp);
    }
    case "UPDATE_BOOK_PAGE": {
      let temp = { ...state };
      temp.book_page = action.value;
      return Object.assign({}, state, temp);
    }
    case "UPDATE_MUSIC_PAGE": {
      let temp = { ...state };
      temp.music_page = action.value;
      return Object.assign({}, state, temp);
    }
    default:
      return state;
  }
}
